<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 1000px; overflow: auto; min-height: 92%; max-height: 92%;">
      <div class="relative">
        <div class="bg-primary flex justify-between p-3 sticky top-0" style="z-index: 100">
          <div class=" font-medium text-white heading-3">
            Add Address
          </div>
        </div>
        <div style="" class="">
          <div class="p-4 ">
             <GoogleMap :showAdditional="showAdditional" :isShowMap="showMap" :selectedRadius="addressObj.selectedRadius" :center="null" :clearSearch="clearSearch" >
            </GoogleMap>
          </div>
          <div v-if="center !== null" class="p-4 pt-0">
            <div class="mb-4">
              <div class="label_css pb-2">Address Title:</div>
                <div >
                  <span @click="changeVal('home')" class="ChipCss heading-6 mr-2" :class="selectedVal === 'home' ? 'bg-primary text-white' : ''">
                    Home
                  </span>
                  <span @click="changeVal('office')" class="ChipCss heading-6 mr-2" :class="selectedVal === 'office' ? 'bg-primary text-white' : ''">
                    Office
                  </span>
                  <span @click="changeVal('other')" class="ChipCss heading-6" :class="selectedVal === 'other' ? 'border border-primary' : ''">
                    <span v-if="selectedVal !== 'other'">{{ CustomVal}}</span>
                    <input id="otherField" v-if="selectedVal === 'other'" v-model="CustomVal" class=" bg-white rounded text-text1 ring-0 outline-none border border-white">
                  </span>
                </div>
              </div>
                <div v-if="showAdditional" class="sm:grid grid-cols-2 gap-4 my-4">
                   <Dropdown 
                    :inputext="addressObj.radiusDispText" 
                    :inputId="'jobloactioninput'"
                    :lableText="'Requires user to be withis (mtr/mi) from the location'"
                    :placholderText="`Select job location`"
                    :autoFocus="false"
                    :showPlus="false"
                    :showArrow="true"
                    :setReadOnly="true"
                    :searchCallApi="false"
                    :keyName="'displayName'"
                    :listId="'radiusdlist'"
                    :items="radiusList"
                    @selectNewForVal="slectRadius"
                    />
                </div>
                <div class="sm:grid grid-cols-2 gap-4 mb-4">
                    <div class="mb-4 sm:mb-0">
                        <TextField
                            :inputId="'housenumid'"
                            :inputext="addressObj.houseNo"
                            :placholderText="`House/Office number`"
                            :lableText="'House/Office number'"
                            :autoFocus="false"
                            @inputChangeAction="(data) => addressObj.houseNo = data"  />
                    </div>
                    <div class="mb-4 sm:mb-0">
                        <TextField
                            :inputId="'floornameeid'"
                            :inputext="addressObj.floorNumber"
                            :placholderText="`Floor number`"
                            :lableText="'Floor number'"
                            :autoFocus="false"
                            @inputChangeAction="(data) => addressObj.floorNumber = data"  />
                    </div>
                    <div class="mb-4 sm:mb-0 col-span-2">
                        <TextField
                            :inputId="'buildingName'"
                            :inputext="addressObj.buildingName"
                            :placholderText="`Apartment/Building name`"
                            :lableText="'Apartment/Building name'"
                            :autoFocus="false"
                            @inputChangeAction="(data) => addressObj.buildingName = data"  />
                    </div>
                    <div class="mb-4 sm:mb-0 col-span-2">
                        <TextAreaInput
                      :inputId="'howtoreach'"
                      :inputext="addressObj.directionNote"
                      :placholderText="`e.g. take first exit from express way`"
                      :lableText="'How to reach'"
                      :inputType="'text'"
                      :autoFocus="false"
                      :textMaxlength="100"
                      :showcharLimit="true"
                      :cols="50"
                      :rows="1"
                      :setReadOnly="false"
                      @inputChangeAction="(data) => addressObj.directionNote = data"  />
                       
                    </div>
                </div>
                <div v-if="showAdditional" class=" sm:grid grid-cols-2 gap-4">
                  <div class="mb-4 sm:mb-0">
                    <TextField
                      :inputId="'contactnameinput'"
                      :inputext="addressObj.contactName"
                      :placholderText="`Contact Name`"
                      :lableText="'Contact Name'"
                      :autoFocus="false"
                      :textMaxlength="25"
                      @inputChangeAction="(data) => addressObj.contactName = data"  />
                  </div>
                  <div class="mb-4 sm:mb-0">
                   <NumberInput
                    :showcharLimit="true"
                    :inputId="'addrescontactnumbid'"
                    :inputext="addressObj.contactNumber"
                    :textMaxlength="12"
                    :placholderText="`Contact Number`"
                    :lableText="'Contact Number'"
                    :inputType="'text'"
                    :autoFocus="false"
                    :fieldError="errObj.contacNumErr !== ''"
                    :setReadOnly="false"
                    @keyPressAction="errObj.contacNumErr = ''"
                    @blurAction="checkContactNumber('prime')"
                    @inputChangeAction="(data) => addressObj.contactNumber = data"  />

                  <p class="text-error heading-7">{{errObj.contacNumErr}}</p>
                  </div>
                </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button :btnSize="'medium'" :disabled="center === null" :textColor="'white'" :btnColor="'success'" :btnText="`Save`" @buttonAction="saveNewAddress()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
/* global google */
import GoogleMap from '@/View/components/GoogleMap.vue'
// import GmapCircle from 'vue2-google-maps/dist/components/circle'
import * as VueGoogleMaps from 'vue2-google-maps'
import Button from '@/View/components/globalButton.vue'
import Dropdown from '@/View/components/dropdown.vue'
import NumberInput from '@/View/components/number-text-field.vue'
// import MyJobApp from '@/api/MyJobApp.js'
import Vue from 'vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import TextField from '@/View/components/textfield.vue'
import store from '@/store'
import { checkSpeialChar } from "@/utils/validation.js";
Vue.use(VueGoogleMaps, {
  load: {
    key: store.getters.googleMapApiKey,
    libraries: 'places,drawing' // necessary for places input
  }
})
export default {
  components: {
    GoogleMap,
    // GmapCircle,
    NumberInput,
    Button,
    TextAreaInput,
    TextField,
    Dropdown,
  },
  data () {
    return {
      selectedVal: 'home',
      CustomVal: 'Other',
      showMap: false,
      clearSearch: false,
      markers: [],
      autocompleteOptions: {
        lat: 0,
        lng: 0
      },
      addressObj: {
        customerAddressId: 0,
        customerId: 0,
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        latitude: '',
        longitude: '',
        fullAddress: '',
        houseNo: '',
        floorNumber: '',
        buildingName: '',
        directionNote: '',
        selectedRadius: 0,
        contactNumber: '',
        contactName: '',
        addressTitle: '',
        },
        center: null,
        address: '',
        radiusList: [
          {displayName: 'Anywhere', value: 0},
          {displayName: '10 meters', value: 10},
          {displayName: '20 meters', value: 20},
          {displayName: '30 meters', value: 30},
          {displayName: '40 meters', value: 40},
          {displayName: '50 meters', value: 50},
          {displayName: '100 meters', value: 100},
          {displayName: '500 meters', value: 500},
          {displayName: '1 km', value: 1000},
          {displayName: '2 km', value: 2000},
          {displayName: '3 km', value: 3000},
        ],
        radiusDispText: 'Anywhere',
        errObj: {
          contacNumErr: ''
        },
    }
  },
  props: ['showAdditional', 'addedAddress'],
   created() {
  },
  mounted () {
    this.$root.$on("GlobalAddressObject", (response) => {
      this.center = {
        lat: response.center.lat,
        lng: response.center.lng
      }
      this.addressObj.addressLine1 = response.addressLine1
      this.addressObj.addressLine2 = response.addressLine2
      this.addressObj.city = response.city
      this.addressObj.state = response.state
      this.addressObj.zip = response.zip
      this.addressObj.country = response.country
      this.addressObj.latitude = response.latitude
      this.addressObj.longitude = response.longitude
      this.addressObj.fullAddress = response.fullAddress
    });
    // document.body.style = 'overflow: hidden;'
    setTimeout(() => {
        // document.getElementById('auto-complete-address').focus()
    }, 500);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(currentPosition => {
        this.autocompleteOptions = {
          lat : currentPosition.coords.latitude,
          lng : currentPosition.coords.longitude,
        }
      })
    }
    else {
      alert('Geolocation is not supported in this browser')
    }
  },
  watch: {
    addedAddress: {
      handler () {
        if (this.addedAddress === null) {
          this.clearSearch = true
          this.clearAll()
        }
      }, deep: true
    }
  },
  methods: {
    changeVal (data) {
      this.selectedVal = data
      if (this.CustomVal === '') {
        this.CustomVal = 'Other'
      }
      if (data === 'other') {
        this.CustomVal = ''
        setTimeout(() => {
          document.getElementById('otherField').focus()
        }, 200);
      }
    },
    mark (event) {
      let newAddress = ''
      var geocoder = new google.maps.Geocoder;
      var latlng = {lat: event.latLng.lat(), lng: event.latLng.lng()};
      geocoder.geocode({'location': latlng}, function(results, status) {
        if (status === 'OK') {
          if (results[1]) {
            newAddress = results[1].formatted_address
          } else {
            window.alert('No results found');
          }
        }
      })
      this.center = {
        lat: latlng.lat,
        lng: latlng.lng
      }
      this.markers = []
      if (this.showAdditional) {
        this.markers.push({ position: this.center })
      }
      setTimeout(() => {
        this.addressObj.fullAddress = newAddress
        this.addressObj.latitude = event.latLng.lat()
        this.addressObj.longitude = event.latLng.lng()
      }, 500);
    },
    checkContactNumber () {
      let value = checkSpeialChar(this.addressObj.contactNumber)
      if (value) {
        this.errObj.contacNumErr = 'Please enter valid contact number'
      } else {
        this.errObj.contacNumErr = ''
      }
      // if (this.addressObj.contactNumber.length < 10 && this.addressObj.contactNumber !== '') {
      //     this.errObj.contacNumErr = 'Please enter valid contact number'
      // } else if (this.addressObj.contactNumber.length === 10 && this.addressObj.contactNumber.trim() !== '' || this.contactNumber.trim() === '') {
      //     this.errObj.contacNumErr = ''
      // }
       
    },
    slectRadius (data) {
      if (data !== null) {
        this.addressObj.selectedRadius = data.value
        this.addressObj.radiusDispText = data.displayName
        this.$root.$emit('radius', this.addressObj.selectedRadius)
      } else {
        this.addressObj.selectedRadius = 0
        this.radiusDispText = 'Anywhere'
      }
    },
    discard () {
      document.body.style = 'overflow: visible;'
      this.$root.$emit('addAddressHandler', false, null)
      if (this.addedAddress) {
      this.addressObj = {
        customerAddressId: this.addedAddress.customerAddressId,
        customerId: this.addedAddress.customerId,
        addressLine1: this.addedAddress.addressLine1,
        addressLine2: this.addedAddress.addressLine2,
        city: this.addedAddress.city,
        state: this.addedAddress.state,
        zip: this.addedAddress.zip,
        country: this.addedAddress.country,
        latitude: this.addedAddress.latitude,
        longitude: this.addedAddress.longitude,
        fullAddress: this.addedAddress.fullAddress,
        houseNo: this.addedAddress.houseNo,
        floorNumber: this.addedAddress.floorNumber,
        buildingName: this.addedAddress.buildingName,
        directionNote: this.addedAddress.directionNote,
        selectedRadius: this.addedAddress.selectedRadius,
        contactNumber: this.addedAddress.contactNumber,
        contactName: this.addedAddress.contactName,
        addressTitle: this.addedAddress.addressTitle,
        }
      } else {
      this.clearAll()
      }
    },
    clearAll () {
      this.selectedVal = 'home'
      this.CustomVal = 'Other'
      this.showMap = false
      this.clearSearch = true
      this.markers = []
      this.autocompleteOptions = {
        lat: 0,
        lng: 0
      }
      this.addressObj = {
        customerAddressId: 0,
        customerId: 0,
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        latitude: '',
        longitude: '',
        fullAddress: '',
        houseNo: '',
        floorNumber: '',
        buildingName: '',
        directionNote: '',
        selectedRadius: 0,
        contactNumber: '',
        contactName: '',
        addressTitle: '',
        }
        this.center = null
        this.address = ''
        this.radiusList = [
          {displayName: 'Anywhere', value: 0},
          {displayName: '10 meters', value: 10},
          {displayName: '20 meters', value: 20},
          {displayName: '30 meters', value: 30},
          {displayName: '40 meters', value: 40},
          {displayName: '50 meters', value: 50},
          {displayName: '100 meters', value: 100},
          {displayName: '500 meters', value: 500},
          {displayName: '1 km', value: 1000},
          {displayName: '2 km', value: 2000},
          {displayName: '3 km', value: 3000},
        ]
        this.radiusDispText = 'Anywhere',
        this.errObj = {
          contacNumErr: ''
        }
    },
    saveNewAddress () {
      let isValid = true
      if (this.showAdditional && this.errObj.contacNumErr !== '') {
        isValid = false
      }
      if (isValid) {
        if (this.selectedVal === 'other') {
          this.addressObj.addressTitle = this.CustomVal
        } else {
          this.addressObj.addressTitle = this.selectedVal
        }
        document.body.style = 'overflow: visible;'
        this.$root.$emit('addAddressHandler', true, this.addressObj)
      }
    },
    setPlace (place) {
        let places= []
      if (place) {
         this.markers = []
        this.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        }
        if (this.showAdditional) {
          this.markers.push({ position: this.center })
        }
        places.push(place)
      }
      let detailData = places
      this.addressObj.latitude = place.geometry.location.lat()
      this.addressObj.longitude = place.geometry.location.lng()
      this.addressObj.street = ''
      this.addressObj.state = ''
      this.addressObj.country = ''
      this.addressObj.zip = ''
      this.addressObj.city = ''
      let mapAddressDetails = []
      let type = ''
      for (let i = 0; i < detailData.length; i++) {
        mapAddressDetails = detailData[i].address_components
        for (let j = 0; j < mapAddressDetails.length; j++) {
          type = mapAddressDetails[j].types[0]
          if (type === 'street_number' || type === 'sublocality_level_2' || type === 'sublocality' || type === 'sublocality_level_1' || type === 'route') {
            this.addressObj.street += ' ' + mapAddressDetails[j].long_name
          }
          if (type === 'administrative_area_level_1') {
            this.addressObj.state = mapAddressDetails[j].short_name
          }
          if (type === 'administrative_area_level_2') {
            this.addressObj.country = mapAddressDetails[j].long_name
          }
          if (type === 'postal_code') {
            this.addressObj.zip = mapAddressDetails[j].long_name
          }

          if (type === 'locality') {
            this.addressObj.city = mapAddressDetails[j].long_name
          }
        }
      }
      this.addressObj.addressLine1 = place.name
      let space = place.name !== '' ? ', ' : ''
      this.addressObj.fullAddress = place.name + space + place.formatted_address
    },
  },
  beforeDestroy () {
    this.$root.$off('GlobalAddressObject')
  }
}
</script>
<style scoped>

</style>